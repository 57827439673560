<template>
  <div>
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>考试分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px">
      <div class="top">
        <Input class="search-bar" search placeholder="考试名称搜索" @on-search="search" v-model="keyword" />
        <el-dropdown @command="handleDropdown" style="margin-top: 3px;">
          <span class="el-dropdown-link"> {{ searchType }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(type, index) in searchTypes" :key="index" :command="index">{{ type }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <Table @on-row-click="toQuestions" :height="th" :data="examData" :columns="examColumn" :loading="loading">
        <template slot-scope="{ row }" slot="avgScore">
          <!--        <div>{{row}}</div>-->
          <span>{{ row.avgScore.toFixed(2) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="op">
          <div style="display: flex">
            <div
              title="成绩分析"
              @click.stop="$router.push({ name: 'examAnalysisDetail', params: { examId: row.examId } })"
              @mouseenter="examId = row.examId"
              @mouseleave="examId = ''"
              style="cursor: pointer"
            >
              <img v-if="examId === row.examId" src="@assets/数据分析@2x-2.png" width="16" height="16" />
              <img v-else src="@assets/数据分析@2x.png" width="16" height="16" />
            </div>
          </div>
        </template>
      </Table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        @on-change="changePage"
        :page-size-opts="[10, 20, 40, 100]"
        @on-page-size-change="changeSize"
        :total="total"
        show-sizer
        show-elevator
        show-total
      />
    </div>
  </div>
</template>

<script>
import statisticApi from '@api/statistic'

export default {
  name: 'Exam',
  data() {
    return {
      delId: '',
      examId: '',
      keyword: '',
      loading: false,
      page: 0,
      size: 10,
      th: document.body.clientHeight - 185,
      total: 0,
      examData: [],
      examColumn: [
        {
          title: '考试名称',
          key: 'name',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '考试参与人数',
          key: 'qualifiedPeople',
          width: 200
        },
        {
          title: '平均分数',
          slot: 'avgScore',
          width: 200
        },
        {
          title: '最高分',
          key: 'maxScore',
          width: 200
        },
        {
          title: '最低分',
          key: 'minScore',
          width: 200
        },
        {
          title: '操作',
          slot: 'op',
          width: 100
        }
      ],
      searchType: '全部',
      type: 0,
      searchTypes: ['全部', '智能出卷', '其他考试']
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/analysis/examQuestions') || from.path.includes('/analysis/scoreDistribution')) {
      to.meta.keepAlive = true
    } else {
      to.meta.keepAlive = false
    }
    next()
  },
  created() {
    this.initExam()
  },
  methods: {
    toQuestions(row) {
      this.$router.push({ name: 'examAnalysisQuestions', params: { examId: row.examId } })
    },
    initExam() {
      this.loading = true
      statisticApi
        .getExamA(this.page, this.size, this.keyword, this.type)
        .then(res => {
          this.total = res.res.total
          this.examData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initExam()
    },
    changeSize(val) {
      this.size = val
      this.initExam()
    },
    search() {
      this.page = 0
      this.size = 10
      this.initExam()
    },
    handleDropdown(command) {
      this.type = command
      this.searchType = this.searchTypes[command]
      this.initExam()
    }
  }
}
</script>

<style scoped lang="less">
.top {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 50px;
  .el-dropdown-link {
    margin-left: 15px;
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .search-bar {
    width: 300px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
}
</style>
